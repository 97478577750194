import OfferCredit from '@/api/resources/offerCredit';

const state = {
  pending_credit_paper: {},
  pending_credit_plastic: {},
  pending_credit_metal: {},
  pending_credit_glass: {},

  available_credit_paper: {},
  available_credit_plastic: {},
  available_credit_metal: {},
  available_credit_glass: {},

  suggest_paper: 0,
  suggest_plastic: 0,
  suggest_metal: 0,
  suggest_glass: 0,
};

const getters = {
  getPaperCreditPending: () => (state.pending_credit_paper),
  getPlasticCreditPending: () => (state.pending_credit_plastic),
  getMetalCreditPending: () => (state.pending_credit_metal),
  getGlassCreditPending: () => (state.pending_credit_glass),

  getAvailablePaperCredit: () => (state.available_credit_paper),
  getAvailablePlasticCredit: () => (state.available_credit_plastic),
  getAvailableMetalCredit: () => (state.available_credit_metal),
  getAvailableGlassCredit: () => (state.available_credit_glass),

  getSuggestPaper: () => (state.suggest_paper),
  getSuggestPlastic: () => (state.suggest_plastic),
  getSuggestMetal: () => (state.suggest_metal),
  getSuggestGlass: () => (state.suggest_glass),
};

const actions = {
  fetchMyCredits({ commit }, payload) {
    OfferCredit.index(payload).then((response) => {
      commit('setMyPendingCredits', response);
      commit('setSuggestValue', response.suggest_value);
    });
  },
  sendCreditsForProofOrigin({ dispatch }) {
    dispatch('nextStepNfe');
  },
};

const mutations = {
  setMyPendingCredits($state, payload) {
    const keys = Object.keys(payload);
    const stateCopy = $state;
    keys.forEach((key) => {
      stateCopy[key] = payload[key];
    });
  },

  setSuggestValue($state, payload) {
    const keys = Object.keys(payload);
    const stateCopy = $state;
    keys.forEach((key) => {
      stateCopy[key] = payload[key];
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
