const state = {
  branch: [],
  branchForEditi: {},
  locationBranch: {},
};

const getters = {
  getBranchForEdit: () => (state.branchForEditi),
  getLocationBranch: () => (state.locationBranch),
  getBranch: () => (state.branch),
};

const actions = {
  addBranch({ commit }, payload) {
    commit('setBranch', payload);
  },
  removBranch({ commit }, payload) {
    commit('deleteBranch', payload);
  },
  editBranch({ commit }, payload) {
    commit('editBranch', payload);
  },
  searchBranch({ commit }, payload) {
    return commit('getOneBranch', payload);
  },
  removeAllBranches({ commit }) {
    commit('cleanBranch');
  },
};

const mutations = {
  setBranchLocation($state, payload) {
    const stateCopy = $state;
    stateCopy.locationBranch = payload;
  },
  setBranch($state, payload) {
    const stateCopy = $state;
    stateCopy.branch.push(payload);
  },
  editBranch($state, payload) {
    const stateCopy = $state;
    stateCopy.branch.splice(payload.index, 1);
    stateCopy.branch.push(payload.branch);
  },
  deleteBranch($state, payload) {
    const stateCopy = $state;
    stateCopy.branch.splice(payload, 1);
  },
  getOneBranch($state, payload) {
    const stateCopy = $state;
    stateCopy.branchForEditi = stateCopy.branch[payload];
  },
  cleanBranch($state) {
    const stateCopy = $state;
    stateCopy.branch = [];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
