import BankAccount from '@/api/resources/bankAccount';
import i18n from '@/plugins/i18n';

const state = {
  bankAccount: {},
};

const getters = {
  getBankAccount: () => (state.bankAccount),
};

const actions = {
  fetchMyBankAccount({ commit }, payload) {
    BankAccount.index(payload).then((response) => {
      commit('setBankAccount', response);
    });
  },

  createBankAccount({ dispatch }, payload) {
    dispatch('initLoading');
    BankAccount.create(payload).then(() => {
      dispatch('addSuccessMessage', i18n.t('success.bank_account_created'));
      dispatch('removeLoading');

      dispatch('fetchMyBankAccount', { business_id: payload.business_id });
    });
  },
};

const mutations = {
  setBankAccount($state, payload) {
    const stateCopy = $state;
    stateCopy.bankAccount = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
