import QuickValue from '@/api/resources/quickValues';

const state = {
  quickValues: [],
};

const getters = {
  getQuickValues: () => (state.quickValues),
};

const actions = {
  fetchQuickValues({ commit }) {
    QuickValue.index().then((response) => {
      commit('setQuickValues', response);
    });
  },
};

const mutations = {
  setQuickValues($state, payload) {
    const stateCopy = $state;
    stateCopy.quickValues = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
