import ApiService from '../api.service';

const BusinessResource = {
  index: (params) => ApiService.query('/v1/business', params),
  create: (params) => ApiService.post('/v1/business', params),
  send_files: (params) => ApiService.post(`/v1/business/${params.id}/documents`, params.formData),
  documents_index: (params) => ApiService.get(`v1/business/${params.business_id}/documents`),
  documents_update: (params) => ApiService.put(`/v1/business/${params.business_id}/documents/${params.id}`, params.formData),
  question: (params) => ApiService.put(`/v1/business/${params.id}/setting_operational_capacity`, { operational_capacity: params.operational_capacity }),
  search_states: () => ApiService.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/'),
  register_mass_data: (params) => ApiService.post(`/v1/business/${params.business_id}/mass_datum`, params),
};

export default BusinessResource;
