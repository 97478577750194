import '@mdi/font/scss/materialdesignicons.scss';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  theme: {
    themes: {
      light: {
        primary: '#8DC63F',
        text_color: '#fff',
        field_register: '#FAFCF7',
        ic_invalid: '#d60808',
        ic_pending: '#d6b408',
        ic_active: '#8DC63F',
        branch_field: '#ECF0E6',
        background_textarea: '#E9EDE4',

        paper_progress: '#99BDCC',
        plastic_progress: '#CC999D',
        glass_progress: '#B6CC99',
        metal_progress: '#B3A77D',

        active: '#8DC63F',
        pending_payment: '#F09539',
        sold: '#27aae1',
      },
      dark: {
        primary: '#474747',
        text_color: '#fff',
      },
    },
  },
  options: {
    customProperties: true,
  },
});
