import User from '@/api/resources/users';

const state = {
  user: null,
  step: 0,
};

const getters = {
  currentUser: () => (state.user),
  getStepUser: () => (state.step),
};

const actions = {
  login({ commit, dispatch }, payload) {
    User.login(payload).then((response) => {
      dispatch('createSession', response);
      dispatch('setKindBusiness', response.businesses[0]);
      commit('setUser', response);
      dispatch('removeLoading');
    });
  },

  recoverPassword({ dispatch }, payload) {
    User.recoverPassword(payload).finally(() => {
      dispatch('removeLoading');
      dispatch('addSuccessMessage', 'Email enviado com sucesso');
    });
  },

  fbLogin({ commit, dispatch }, payload) {
    User.fbLogin(payload).then((response) => {
      dispatch('createSession', response);
      commit('setUser', response);
      dispatch('removeLoading');
    });
  },

  googleLogin({ commit, dispatch }, payload) {
    User.googleLogin(payload).then((response) => {
      dispatch('createSession', response);
      commit('setUser', response);
      dispatch('removeLoading');
    });
  },

  appleLogin({ commit, dispatch }, payload) {
    User.appleLogin(payload).then((response) => {
      dispatch('createSession', response);
      commit('setUser', response);
      dispatch('removeLoading');
    });
  },

  refreshUser({ commit, dispatch }) {
    User.show().then((response) => {
      dispatch('refreshBusiness', response.businesses[0]);
      dispatch('createSession', response);
      commit('setUser', response);
      dispatch('removeLoading');
    });
  },

  register({ commit, dispatch }, payload) {
    User.register(payload).then((response) => {
      commit('setUser', response);
      dispatch('createSession', response);
      dispatch('removeLoading');
      commit('nextStep');
    });
  },

  logout({ commit, dispatch }) {
    commit('setUser', null);
    dispatch('destroySession');
  },

  setStepUser({ commit }) {
    commit('setStep');
  },

  nextStepUser({ commit }) {
    commit('nextStep');
  },
  backStepUser({ commit }) {
    commit('backStep');
  },
};

const mutations = {
  setUser($state, payload) {
    const stateCopy = $state;
    stateCopy.user = payload;
  },
  nextStep($state) {
    const stateCopy = $state;
    stateCopy.step += 1;
  },
  backStep($state) {
    const stateCopy = $state;
    stateCopy.step -= 1;
  },
  setStep($state) {
    const stateCopy = $state;
    stateCopy.step = 0;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
