import CartItem from '@/api/resources/cartItem';
import i18n from '@/plugins/i18n';

const state = {
  mandatoryOffersDialog: false,
  mandatoryOffers: [],
  cartItemsToRemove: [],
};

const getters = {
  getMandatoryOffers: () => (state.mandatoryOffers),
  hasMandatoryOffers: () => (state.mandatoryOffers.length > 0),
  getMandatoryOffersDialog: () => (state.mandatoryOffersDialog),
};

const actions = {
  openMandatoryOffersDialog({ commit }) {
    commit('setMandatoryOffersDialog', true);
  },

  closeMandatoryOffersDialog({ commit }) {
    commit('setMandatoryOffersDialog', false);
  },

  setMandatoryOffers({ commit }, payload) {
    commit('setMandatoryOffers', payload);
  },

  setCartItemsToRemove({ commit }, payload) {
    commit('setCartItemsToRemove', payload);
  },

  async removeCartItemsWithRelation({ dispatch }) {
    dispatch('initLoading');

    const promises = state.cartItemsToRemove.map((id) => CartItem.delete(id));

    await Promise.all(promises).then(() => {
      dispatch('addSuccessMessage', i18n.t('success.remove_cart_items'));
      dispatch('removeLoading');
    });
  },
};

const mutations = {
  setMandatoryOffersDialog($state, payload) {
    const stateCopy = $state;
    stateCopy.mandatoryOffersDialog = payload;
  },

  setMandatoryOffers($state, payload) {
    const stateCopy = $state;
    stateCopy.mandatoryOffers = payload;
  },

  setCartItemsToRemove($state, payload) {
    const stateCopy = $state;
    stateCopy.cartItemsToRemove = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
