import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import Loading from './modules/loading';
import CustomLoading from './modules/CustomLoading';
import Session from './modules/session';
import Snackbar from './modules/snackbar';
import Error from './modules/error';
import User from './modules/user';
import Nfe from './modules/nfe';
import Business from './modules/business';
import Branch from './modules/branch';
import ProofOrigin from './modules/proofOrigin';
import BudgetSolicitation from './modules/budgetSolicitation';
import CreditOffered from './modules/creditOffered';
import OfferCredit from './modules/offerCredit';
import CartItem from './modules/cartItem';
import MyOffereds from './modules/myOffereds';
import BankAccount from './modules/bankAcount';
import Order from './modules/order';
import QuickValue from './modules/quickValue';
import PriceTable from './modules/priceTable';
import BuyingInterest from './modules/buyingInterest';
import BuyingInterestDialog from './modules/buyingInterestDialog';
import StepperNfe from './modules/stepperNfe';
import ConfirmOrderDialog from './modules/confirmOrderDialog';
import mandatoryOffersDialog from './modules/mandatoryOffersDialog';
import deleteRelatedOffersDialog from './modules/deleteRelatedOffersDialog';

Vue.use(Vuex);

const persistedStateData = createPersistedState({
  paths: ['Session.email', 'Session.token', 'Business.business'],
});

export default new Vuex.Store({
  modules: {
    Business,
    ConfirmOrderDialog,
    CreditOffered,
    Branch,
    MyOffereds,
    BuyingInterestDialog,
    ProofOrigin,
    User,
    PriceTable,
    Nfe,
    OfferCredit,
    Loading,
    CustomLoading,
    CartItem,
    Session,
    Snackbar,
    Error,
    BudgetSolicitation,
    BankAccount,
    Order,
    QuickValue,
    BuyingInterest,
    StepperNfe,
    mandatoryOffersDialog,
    deleteRelatedOffersDialog,
  },
  plugins: [persistedStateData],
});
