import BudgetSolicitation from '@/api/resources/budgetSolicitation';

const state = {
  budgetSolicitation: {},
};

const getters = {
  getBudgetSolicitation: ($state) => $state.budgetSolicitation,
};

const actions = {
  createBudgetSolicitation({ dispatch }, payload) {
    dispatch('initLoading');
    BudgetSolicitation.create(payload).then(() => {
      dispatch('removeLoading');
      dispatch('addSuccessMessage');
    });
  },
};

export default {
  state,
  getters,
  actions,
};
