import { consultarCep } from 'correios-brasil';
import Business from '@/api/resources/business';
import i18n from '@/plugins/i18n';

const state = {
  business: {
    branch: [],
  },
  brazilianStates: [],
  locationBusiness: {},
  step: 2,
  subStep: 1,
  operationalCapacity: [],
  administrative: {},
  operatingEquipment: {},
  healthSecurity: {},
  wasteDestination: {},
  programsPartnerships: {},
  conclusion: {},
  massData: {},
  offerCredit: {},
  allBusiness: [],
  businessDocuments: {},
};

const getters = {
  getBusiness: ($state) => ($state.business),
  getStep: ($state) => ($state.step),
  getSubStep: ($state) => ($state.subStep),
  getLocationBusiness: ($state) => ($state.locationBusiness),
  getQuestions: ($state) => ($state.operationalCapacity),
  getBrazilianStates: ($state) => ($state.brazilianStates),
  hasBusiness: (state) => {
    if (!state.business.id) return false;
    return true;
  },
  getMassData: ($state) => ($state.massData),
  getAllBusiness: ($state) => ($state.allBusiness),
  getDocuments: ($state) => ($state.businessDocuments),
  hasOperationalCapacity: ($state) => {
    if ($state.businessDocuments?.operational_capacity) return true;
    return false;
  },
  getAdministrative: ($state) => ($state.administrative),
  getOperatingEquipment: ($state) => ($state.operatingEquipment),
  getHealthSecurity: ($state) => ($state.healthSecurity),
  getWasteDestination: ($state) => ($state.wasteDestination),
  getProgramsPartnerships: ($state) => ($state.programsPartnerships),
  getConclusion: ($state) => ($state.conclusion),
};

const actions = {
  setKindBusiness({ commit }, payload) {
    commit('setBusiness', payload);
  },
  refreshBusiness({ commit }, payload) {
    commit('setBusiness', payload);
  },
  setSubStepsBusiness({ commit }) {
    commit('setSubSteps');
  },
  backSubStepBusiness({ commit }) {
    commit('backSubStep');
  },
  removeBusiness({ commit }) {
    commit('destroyBusiness');
  },
  registerOrganization({ commit, dispatch }, payload) {
    Business.create(payload).then((response) => {
      commit('setBusiness', response);
      dispatch('nextStepUser');
      dispatch('removeLoading');
    });
  },
  nextSubStep({ commit }) {
    commit('nextSubStep');
  },
  backSubStep({ commit }) {
    commit('backSubStep');
  },
  sendDocumentsFiles({ dispatch }, payload) {
    Business.send_files(payload).then(() => {
      dispatch('nextStepUser');
      dispatch('removeLoading');
    });
  },
  registerMassData({ commit, dispatch }, payload) {
    Business.register_mass_data(payload).then(() => {
      commit('setMassData', payload);
      dispatch('nextStepUser');
      dispatch('removeLoading');
    });
  },
  searchBusinessZipCode({ dispatch, commit }, payload) {
    dispatch('initLoading');
    consultarCep(payload).then((data) => {
      commit('setLocationInformation', data);
      dispatch('removeLoading');
    }).catch(() => {
      dispatch('removeLoading');
      dispatch('addErrorMessage', i18n.t('errors.zip_code_invalid'));
    });
  },
  searchBranchZipCode({ dispatch, commit }, payload) {
    dispatch('initLoading');
    consultarCep(payload).then((data) => {
      commit('setBranchLocation', data);
      dispatch('removeLoading');
    }).catch(() => {
      dispatch('removeLoading');
      dispatch('addErrorMessage', i18n.t('errors.zip_code_invalid'));
    });
  },
  searchState({ commit }, payload) {
    Business.search_states(payload).then((response) => {
      commit('setBrazilianStates', response);
    });
  },
  setQuestionAdministrative({ commit }, payload) {
    commit('questionAdministrative', payload);
  },
  setQuestionOperatingEquipment({ commit }, payload) {
    commit('questionOperatingEquipment', payload);
  },
  setQuestionHealthSecurity({ commit }, payload) {
    commit('questionHealthSecurity', payload);
  },
  setQuestionWasteDestination({ commit }, payload) {
    commit('questionWasteDestination', payload);
  },
  setQuestionProgramsPartnerships({ commit }, payload) {
    commit('questionProgramsPartnerships', payload);
  },
  setQuestionConclusion({ commit }, payload) {
    commit('questionConclusion', payload);
  },
  setStepsBusiness({ commit }, payload) {
    commit('setSteps', payload);
  },
  mountQuestion({ commit }) {
    commit('pushQuestion');
  },
  sendQuestions({ dispatch }, payload) {
    Business.question(payload).then(() => {
      dispatch('refreshUser');
      dispatch('removeLoading');
    });
  },
  setBranchInbusiness({ commit }, payload) {
    commit('setBranchInBusiness', payload);
  },
  indexBusiness({ commit }, payload) {
    Business.index(payload).then((response) => {
      commit('setAllBusiness', response);
    });
  },
  fetchBusinessDocuments({ commit }, payload) {
    Business.documents_index(payload).then((response) => {
      commit('setBusinessDocuments', response);
    });
  },
  createBusinessDocuments({ commit, dispatch }, payload) {
    Business.send_files(payload).then((response) => {
      commit('setBusinessDocuments', response);
      dispatch('addSuccessMessage', i18n.t('success.documents_created'));
    });
  },
  updateBusinessDocuments({ commit, dispatch }, payload) {
    Business.documents_update(payload).then((response) => {
      commit('setBusinessDocuments', response);
      dispatch('addSuccessMessage', i18n.t('success.documents_updated'));
    });
  },
  fetchOperationalCapacity({ commit, dispatch }, payload) {
    dispatch('fetchBusinessDocuments', payload);
    commit('setOperationalCapacityQuestions');
  },
};

const mutations = {
  setBusiness($state, payload) {
    const stateCopy = $state;
    stateCopy.business = { ...payload };
  },
  destroyBusiness($state) {
    const stateCopy = $state;
    stateCopy.business = {};
  },
  setLocationInformation($state, payload) {
    const stateCopy = $state;
    stateCopy.locationBusiness = payload;
  },
  setSteps($state, payload) {
    const stateCopy = $state;
    stateCopy.step = payload;
  },
  setSubSteps($state) {
    const stateCopy = $state;
    stateCopy.subStep = 1;
  },
  backSubStep($state) {
    const stateCopy = $state;
    stateCopy.subStep -= 1;
  },
  nextStep($state) {
    const stateCopy = $state;
    if (stateCopy.step < 4) stateCopy.step += 1;
  },
  pushQuestion($state) {
    const stateCopy = $state;
    stateCopy.subStep += 1;
    stateCopy.operationalCapacity = [];
    stateCopy.operationalCapacity.push(
      { step1: stateCopy.administrative },
      { step2: stateCopy.operatingEquipment },
      { step3: stateCopy.healthSecurity },
      { step4: stateCopy.wasteDestination },
      { step5: stateCopy.programsPartnerships },
      { step6: stateCopy.conclusion },
    );
  },
  nextSubStep($state) {
    const stateCopy = $state;
    if (stateCopy.subStep < 7) stateCopy.subStep += 1;
  },
  questionAdministrative($state, payload) {
    const stateCopy = $state;
    stateCopy.administrative = payload;
  },
  questionOperatingEquipment($state, payload) {
    const stateCopy = $state;
    stateCopy.operatingEquipment = payload;
  },
  questionHealthSecurity($state, payload) {
    const stateCopy = $state;
    stateCopy.healthSecurity = payload;
  },
  questionWasteDestination($state, payload) {
    const stateCopy = $state;
    stateCopy.wasteDestination = payload;
  },
  questionProgramsPartnerships($state, payload) {
    const stateCopy = $state;
    stateCopy.programsPartnerships = payload;
  },
  questionConclusion($state, payload) {
    const stateCopy = $state;
    stateCopy.conclusion = payload;
  },
  setBranchInBusiness($state, payload) {
    const stateCopy = $state;
    stateCopy.business.branch = payload;
  },
  setBrazilianStates($state, payload) {
    const stateCopy = $state;
    stateCopy.brazilianStates = [];
    const acronyms = payload.map((state) => state.sigla);
    stateCopy.brazilianStates = acronyms.sort();
  },
  setMassData($state, payload) {
    const stateCopy = $state;
    stateCopy.massData = payload;
  },
  setAllBusiness($state, payload) {
    const stateCopy = $state;
    stateCopy.allBusiness = payload;
  },
  setBusinessDocuments($state, payload) {
    const stateCopy = $state;
    stateCopy.businessDocuments = payload;
  },
  setOperationalCapacityQuestions($state) {
    const stateCopy = $state;
    const operationalCapacityParsed = JSON.parse(stateCopy.businessDocuments.operational_capacity);
    const [step1, step2, step3, step4, step5, step6] = operationalCapacityParsed;
    stateCopy.administrative = step1.step1;
    stateCopy.operatingEquipment = step2.step2;
    stateCopy.healthSecurity = step3.step3;
    stateCopy.wasteDestination = step4.step4;
    stateCopy.programsPartnerships = step5.step5;
    stateCopy.conclusion = step6.step6;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
