import BuyingInterest from '@/api/resources/buyingInterest';
import i18n from '@/plugins/i18n';

const state = {
  buyingInterests: [],
  buyingInterest: {},
};

const getters = {
  getBuyingInterests: (state) => state.buyingInterests,
  getBuyingInterest: (state) => state.buyingInterest,
};

const actions = {
  async createBuyingInterest({ commit, dispatch }, payload) {
    dispatch('initLoading');

    await BuyingInterest.createBuyingInterest(payload).then((response) => {
      commit('setBuyingInterest', response.data);
      dispatch('removeLoading');
      dispatch('addSuccessMessage', i18n.t('success.buying_interest_created'));
      dispatch('indexBuyingInterest', payload);
    });
  },

  indexBuyingInterest({ commit }, payload) {
    BuyingInterest.indexBuyingInterest(payload).then((response) => {
      commit('setBuyingInterests', response);
    });
  },

  async removeBuyingInterest({ dispatch }, payload) {
    dispatch('initLoading');
    await BuyingInterest.removeBuyingInterest(payload).then(() => {
      dispatch('removeLoading');
      dispatch('addSuccessMessage', i18n.t('success.buying_interest_removed'));
      dispatch('indexBuyingInterest', payload);
    });
  },

  setBuyingInterestToState({ commit }, payload) {
    commit('setBuyingInterest', payload);
  },

};

const mutations = {
  setBuyingInterests($state, payload) {
    const stateCopy = $state;
    stateCopy.buyingInterests = payload;
  },

  setBuyingInterest($state, payload) {
    const stateCopy = $state;
    stateCopy.buyingInterest = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
