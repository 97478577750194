import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/home',
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/Register.vue'),
      meta: {
        needToolbar: false,
        icon: 'mdi-arrow-left',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        icon: 'none',
      },
    },
    {
      path: '/recover-password',
      name: 'recover-password',
      component: () => import('@/views/RecoverPassword.vue'),
      meta: {
        icon: 'none',
      },
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        requiresAuth: true,
        needToolbar: true,
      },
    },
    {
      path: '/wallet',
      name: 'wallet',
      component: () => import('@/views/Wallet.vue'),
      meta: {
        requiresAuth: true,
        needToolbar: true,
      },
    },
    {
      path: '/wallet/my-offereds',
      name: 'my-offereds',
      component: () => import('@/views/MyOffereds.vue'),
      meta: {
        requiresAuth: true,
        needToolbar: true,
      },
    },
    {
      path: '/notify',
      name: 'notify',
      component: () => import('@/views/Todo.vue'),
      meta: {
        requiresAuth: true,
        needToolbar: true,
      },
    },
    {
      path: '/sac',
      name: 'sac',
      component: () => import('@/views/Support.vue'),
      meta: {
        requiresAuth: true,
        needToolbar: true,
      },
    },
    {
      path: '/extract',
      name: 'extract',
      component: () => import('@/views/Todo.vue'),
      meta: {
        requiresAuth: true,
        needToolbar: true,
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/Todo.vue'),
      meta: {
        requiresAuth: true,
        needToolbar: true,
      },
    },
    {
      path: '/send-nfe',
      name: 'send-nfe',
      component: () => import('@/views/SendNfe.vue'),
      meta: {
        requiresAuth: true,
        needToolbar: true,
      },
    },
    {
      path: '/offered/:id',
      name: 'offered',
      component: () => import('@/views/Offered.vue'),
      meta: {
        requiresAuth: true,
        needToolbar: true,
      },
    },
    {
      path: '/targeted-offered/:token',
      name: 'targeted-offered',
      component: () => import('@/views/TargetedOffered.vue'),
      meta: {
        requiresAuth: true,
        needToolbar: true,
      },
    },
    {
      path: '/cart',
      name: 'cart',
      component: () => import('@/views/Cart.vue'),
      meta: {
        requiresAuth: true,
        needToolbar: true,
      },
    },
    {
      path: '/checkout',
      name: 'checkout',
      component: () => import('@/views/Checkout.vue'),
      meta: {
        requiresAuth: true,
        needToolbar: true,
      },
    },
    {
      path: '/offer-credit',
      name: 'offer-credit',
      component: () => import('@/views/OfferCredit.vue'),
      meta: {
        requiresAuth: true,
        needToolbar: true,
      },
    },
    {
      path: '/create-credit-offered',
      name: 'create-credit-offered',
      component: () => import('@/views/CreateCreditOffered.vue'),
      meta: {
        requiresAuth: true,
        needToolbar: true,
      },
    },
    {
      path: '/bank-account',
      name: 'bank-account',
      component: () => import('@/views/BankAccount.vue'),
      meta: {
        requiresAuth: true,
        needToolbar: true,
      },
    },
    {
      path: '/credit-offered',
      name: 'credit-offered',
      component: () => import('@/views/CreditOffered.vue'),
      meta: {
        requiresAuth: true,
        needToolbar: true,
      },
    },
    {
      path: '/business-registration',
      name: 'business',
      component: () => import('@/views/RegisterBusiness.vue'),
      meta: {
        requiresAuth: true,
        needToolbar: false,
      },
    },
    {
      path: '/orders',
      name: 'orders',
      component: () => import('@/views/Orders.vue'),
      meta: {
        requiresAuth: true,
        needToolbar: true,
      },
    },
    {
      path: '/orders/:id',
      name: 'order',
      component: () => import('@/views/Order.vue'),
      meta: {
        requiresAuth: true,
        needToolbar: true,
      },
    },
    {
      path: '/documents',
      name: 'document',
      component: () => import('@/views/Document.vue'),
      meta: {
        requiresAuth: true,
        needToolbar: true,
      },
    },
    {
      path: '/update-documents',
      name: 'update-documents',
      component: () => import('@/views/UpdateDocuments.vue'),
      meta: {
        requiresAuth: true,
        needToolbar: true,
      },
    },
    {
      path: '/market',
      name: 'market',
      component: () => import('@/views/Market.vue'),
      meta: {
        requiresAuth: true,
        needToolbar: true,
      },
    },
    {
      path: '/certificates',
      name: 'certificates',
      component: () => import('@/views/Certificates.vue'),
      meta: {
        requiresAuth: true,
        needToolbar: true,
      },
    },
  ],
});

export default routes;
