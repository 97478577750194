import Order from '@/api/resources/order';

const state = {
  orders: [],
  order: {},
};

const getters = {
  getOrders: () => (state.orders),
  getOrder: () => (state.order),
  orderIsPending: () => (state.order.status === 'pending' && !!state.order?.payment),
  orderIsPaid: () => state.order.status === 'paid',
};

const actions = {
  fetchOrders({ commit }, payload) {
    Order.index(payload).then((response) => {
      commit('setOrders', response);
    });
  },

  /* eslint-disable camelcase */
  async createOrder({ commit, dispatch }, payload) {
    dispatch('initLoading');

    await Order.create(payload).then((response) => {
      dispatch('removeLoading');

      commit('setOrder', response);
      dispatch('confirmOrderDialog', true);
    }).catch(({ response: { data: { credit_offereds_to_add, cart_item_to_remove } } }) => {
      if (credit_offereds_to_add && credit_offereds_to_add.length > 0) {
        dispatch('setMandatoryOffers', credit_offereds_to_add);
        dispatch('setCartItemsToRemove', cart_item_to_remove);
        dispatch('openMandatoryOffersDialog');
      }
    });
  },

  showOrder({ commit, dispatch }, payload) {
    dispatch('initLoading');
    Order.show(payload).then((response) => {
      dispatch('removeLoading');
      commit('setOrder', response);
    });
  },
};
const mutations = {
  setOrders($state, payload) {
    const stateCopy = $state;
    stateCopy.orders = payload;
  },

  setOrder($state, payload) {
    const stateCopy = $state;
    stateCopy.order = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
