import ProofOrigin from '@/api/resources/proofOrigin';

const actions = {
  sendFilesProofOrigins({ dispatch }, payload) {
    dispatch('initLoading');
    ProofOrigin.send(payload).then(() => {
      dispatch('removeLoading');
      dispatch('proofOriginSuccess', true);
    });
  },

  setFileForProofOrigin({ commit }, payload) {
    commit('setNfesFilesWithSuccess', payload);
  },

  autoProofOrigin({ dispatch }, payload) {
    dispatch('initLoading');
    ProofOrigin.autoProof(payload).then(() => {
      dispatch('proofOriginSuccess', true);
      dispatch('removeLoading');
    });
  },
};

export default { actions };
