import ApiService from '../api.service';

const CartItemResource = {
  index: (params) => ApiService.query('/v1/users/me/cart_items', params),
  create: (params) => ApiService.post('/v1/users/me/cart_items', params),
  delete: (id) => ApiService.delete(`/v1/users/me/cart_items/${id}`),
  update: (payload) => ApiService.put(`/v1/users/me/cart_items/${payload.id}`, payload),
};

export default CartItemResource;
