import i18n from '@/plugins/i18n';
import CreditOffered from '@/api/resources/creditOffered';

const state = {
  creditOffereds: [],
  creditOffered: {},
  created: false,
  filterByState: '',
  filterLogisticOperator: [
    {
      name: 'Cooperativa',
      kind: 'cooperative_association',
      selected: false,
    },
    {
      name: 'Privado',
      kind: 'waste_merchant',
      selected: false,
    },
  ],
  filterMaterial: [
    {
      name: 'Papel',
      selected: false,
    },
    {
      name: 'Plástico',
      selected: false,
    },
    {
      name: 'Vidro',
      selected: false,
    },
    {
      name: 'Metal',
      selected: false,
    },
  ],
};

const getters = {
  getCreditOffereds: () => (state.creditOffereds),
  getCreditOffered: () => (state.creditOffered),
  getFilterLogisticOperator: () => (state.filterLogisticOperator),
  getFilterMaterial: () => (state.filterMaterial),
  getFilterByState: () => (state.filterByState),
  createdOffer: () => (state.created),
};

const actions = {
  fetchCreditOffereds({ commit, dispatch }, payload) {
    CreditOffered.index(payload).then((response) => {
      commit('setCreditOffereds', response);
      dispatch('removeLoading');
    });
  },

  fetchCreditOffered({ commit, dispatch }, payload) {
    CreditOffered.show(payload).then((response) => {
      commit('setCreditOffered', response);
      dispatch('removeLoading');
    });
  },

  fetchTargetedCreditOffered({ commit, dispatch }, payload) {
    CreditOffered.showTargetedCreditOffered(payload).then((response) => {
      commit('setCreditOffered', response);
      dispatch('removeLoading');
    });
  },

  createOffer({ dispatch, commit }, payload) {
    dispatch('initLoading');
    commit('setCreated', false);
    CreditOffered.create(payload).then(() => {
      dispatch('fetchMyCredits', { business_id: payload.business_id });
      dispatch('fetchMyOffereds', payload.business_id);
      dispatch('removeLoading');
      dispatch('addSuccessMessage', i18n.t('success.created_credit'));
      commit('setCreated', true);
    });
  },

  createTargettedOffer({ dispatch, commit }, payload) {
    dispatch('initLoading');
    commit('setCreated', false);
    CreditOffered.createCreditOfferedTargetted(payload).then(() => {
      dispatch('fetchMyCredits', { business_id: payload.business_id });
      dispatch('fetchMyOffereds', payload.business_id);
      dispatch('removeLoading');
      dispatch('addSuccessMessage', i18n.t('success.created_credit'));
      commit('setCreated', true);
    });
  },

  changeFilterMaterial({ commit, getters }, index) {
    const filterMaterials = [...getters.getFilterMaterial];
    filterMaterials[index].selected = !filterMaterials[index].selected;
    commit('setFilterMaterial', filterMaterials);
  },

  changeFilterLogisticOperator({ commit, getters }, index) {
    const filterLogisticOperator = [...getters.getFilterLogisticOperator];
    filterLogisticOperator[index].selected = !filterLogisticOperator[index].selected;
    commit('setFilterLogisticOperator', filterLogisticOperator);
  },

  changeFilterByState({ commit }, payload) {
    commit('setFilterByState', payload);
  },
};

const mutations = {
  setCreditOffereds($state, payload) {
    const stateCopy = $state;
    stateCopy.creditOffereds = payload;
  },

  setCreditOffered($state, payload) {
    const stateCopy = $state;
    stateCopy.creditOffered = payload;
  },

  setCreated($state, payload) {
    const stateCopy = $state;
    stateCopy.created = payload;
  },

  setFilterMaterial($state, payload) {
    const stateCopy = $state;
    stateCopy.filterMaterial = payload;
  },

  setFilterLogisticOperator($state, payload) {
    const stateCopy = $state;
    stateCopy.filterLogisticOperator = payload;
  },

  setFilterByState($state, payload) {
    const stateCopy = $state;
    stateCopy.filterByState = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
