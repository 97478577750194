<template>
  <div
    v-if="hasLoading"
    class="loading"
  >
    <div class="trash-img">
      <img
        class="trash"
        src="@/assets/icons/custom_load/trash_blue.svg"
      >
      <img
        class="trash"
        src="@/assets/icons/custom_load/trash_green.svg"
      >
      <img
        class="trash"
        src="@/assets/icons/custom_load/trash_red.svg"
      >
      <img
        class="trash"
        src="@/assets/icons/custom_load/trash_yellow.svg"
      >
    </div>
    <div class="text-load">
      {{ $t('components.custom_loading.send_nfe') }}
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'CustomLoading',
  computed: {
    ...mapState([
      'CustomLoading',
    ]),
    hasLoading() {
      return this.CustomLoading.loading;
    },
  },
};
</script>
<style lang="scss" src="@/assets/scss/components/feedbacks/custom_load.scss" scoped/>
