<template>
  <v-app>
    <loading />
    <custom-loading />
    <toolbar-nav
      v-if="needToolbar"
      v-show="!$vuetify.breakpoint.smAndDown"
    />
    <v-app-bar
      v-if="needToolbar"
      v-show="$vuetify.breakpoint.smAndDown"
    >
      {{ $t('app_name') }}
    </v-app-bar>
    <div class="main-app">
      <v-main
        id="main-app"
      >
        <v-container
          class="app-container pa-0"
          fluid
        >
          <router-view />
        </v-container>
      </v-main>
    </div>
    <snackbar />
    <vordesk />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import ToolbarNav from './components/navs/ToolbarNav.vue';
import Loading from './components/feedbacks/Loading.vue';
import CustomLoading from './components/feedbacks/CustomLoading.vue';
import Snackbar from './components/feedbacks/Snackbar.vue';
import Vordesk from './components/feedbacks/Vordesk.vue';

export default {
  name: 'App',
  components: {
    ToolbarNav,
    Loading,
    Snackbar,
    CustomLoading,
    Vordesk,
  },
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    ...mapGetters([
      'hasSession',
      'getStepUser',
    ]),
    needToolbar() {
      return (this.$route.meta.needToolbar && this.hasSession);
    },
  },
  watch: {
    hasSession(hasSession) {
      if (!hasSession) {
        this.$router.push({ name: 'login' });
      }
    },
    getStepUser(value) {
      if (value === 5) {
        this.$router.push({ name: 'home' });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .app-container{
    background: $app_background;
    min-height: 100%;
  }
</style>
