const state = {
  step: 1,
  nfeSendSuccess: false,
  proofOriginSuccess: false,
};

const getters = {
  getStepNfe: () => (state.step),
  getNfeSendSuccess: () => (state.nfeSendSuccess),
  getProofOriginSuccess: () => (state.proofOriginSuccess),
};

const actions = {
  nextStepNfe({ commit }) {
    commit('nextStep');
  },

  setStepsNfe({ commit }, payload) {
    commit('setSteps', payload);
  },

  nfeSendSuccess({ commit }, payload) {
    commit('setNfeSendSuccess', payload);
  },

  proofOriginSuccess({ commit }, payload) {
    commit('setProofOriginSuccess', payload);
  },

  resetStateOfSuccess({ commit }) {
    commit('setNfeSendSuccess', false);
    commit('setProofOriginSuccess', false);
  },
};

const mutations = {
  nextStep($state) {
    const stateCopy = $state;
    stateCopy.step += 1;
  },

  setSteps($state, payload) {
    const stateCopy = $state;
    stateCopy.step = payload;
  },

  setNfeSendSuccess($state, payload) {
    const stateCopy = $state;
    stateCopy.nfeSendSuccess = payload;
  },

  setProofOriginSuccess($state, payload) {
    const stateCopy = $state;
    stateCopy.proofOriginSuccess = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
