import ApiService from '../api.service';

const CreditOfferedResource = {
  index: (params) => ApiService.query('/v1/credit_offereds', params),
  show: (id) => ApiService.get(`/v1/credit_offereds/${id}`),
  showTargetedCreditOffered: (token) => ApiService.get(`/v1/credit_offereds/targeted_credit_offered/${token}`),
  create: (params) => ApiService.post(`/v1/business/${params.business_id}/credit_offereds`, params),
  createCreditOfferedTargetted: (params) => ApiService.post(`v1/business/${params.business_id}/credit_offereds/targeted_credit_offered`, params),
};

export default CreditOfferedResource;
