import MyOfferedResource from '@/api/resources/myCreditOffered';
import i18n from '../../plugins/i18n';

const state = {
  myOffereds: [],
};

const getters = {
  getMyOffereds: () => (state.myOffereds),
};

const actions = {
  fetchMyOffereds({ commit, dispatch }, payload) {
    MyOfferedResource.index(payload).then((response) => {
      commit('setMyOffereds', response);
      dispatch('removeLoading');
    });
  },

  deleteMyCreditOffered({ dispatch }, payload) {
    MyOfferedResource.delete(payload).then(() => {
      dispatch('addSuccessMessage', i18n.t('success.deleted_credit_offered'));
      dispatch('fetchMyOffereds', payload.business_id);
    });
  },

  updateMyCreditOffered({ dispatch }, payload) {
    MyOfferedResource.update(payload).then(() => {
      dispatch('addSuccessMessage', i18n.t('success.updated_credit_offered'));
      dispatch('fetchMyOffereds', payload.business_id);
    });
  },
};

const mutations = {
  setMyOffereds($state, payload) {
    const stateCopy = $state;
    stateCopy.myOffereds = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
