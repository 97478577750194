<template>
  <div class="navigation">
    <v-navigation-drawer
      v-model="drawer"
      width="14em"
      fixed
      permanent
    >
      <navigation-drawer />
    </v-navigation-drawer>
  </div>
</template>
<script>
import NativeBridge from '@/common/nativebridge';
import NavigationDrawer from './NavigationDrawer.vue';

export default {
  name: 'ToolbarNav',
  components: {
    NavigationDrawer,
  },
  data() {
    return { drawer: false };
  },
  computed: {
    navIcon() {
      if (this.$route.meta.icon) { return this.$route.meta.icon; }
      return 'mdi-menu';
    },
  },
  methods: {
    setAction() {
      if (this.navIcon === 'mdi-arrow-left') {
        if (this.$route.meta.backToApp) {
          NativeBridge.directCall('open-app', () => {});
        } else {
          window.history.go(-1);
        }
      } else {
        this.drawer = !this.drawer;
      }
    },
  },
};
</script>
