const state = {
  buyingInterestDialog: false,
};

const getters = {
  getBuyingInterestDialog: () => (state.buyingInterestDialog),
};

const actions = {
  changeBuyingInterestDialog({ commit }, payload) {
    commit('setDialog', payload);
  },
};

const mutations = {
  setDialog($state, payload) {
    const stateCopy = $state;
    stateCopy.buyingInterestDialog = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
