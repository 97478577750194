<template>
  <div class="navigation-drawer">
    <div class="nav-container">
      <v-list>
        <v-list-item>
          <div class="nav-logo">
            <img
              src="@/assets/rec_green.png"
              class="img-logo"
            >
          </div>
        </v-list-item>
      </v-list>
      <v-list-item-group class="content-nav">
        <v-list
          class="pt-0"
          dense
        >
          <v-list-item
            v-for="item in itemsNav"
            :key="item.title"
            :class="[routeSelected(item.destination), 'ma-0 item']"
            @click="goTo(item.action)"
          >
            <v-list-item-action>
              <v-img
                v-if="!iconEnable(item.destination)"
                :src="item.icon"
                class="icon-img"
              />
              <v-img
                v-else
                :src="item.selected"
                class="icon-img"
              />
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title :class="[itemSelected(item.destination), 'item-nav']">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
        </v-list>
      </v-list-item-group>
      <v-list
        class="item-end pt-0"
        dense
      >
        <v-list-item
          class="ma-0 item"
          @click="logout"
        >
          <v-list-item-action>
            <v-img
              :src="itemLogout.icon"
              class="icon-img"
            />
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title class="item-nav">
              {{ itemLogout.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

// Icons Disabled
import logout from '@/assets/icons/nav/ic_logout.svg';
import extract from '@/assets/icons/nav/ic_extract.svg';
import home from '@/assets/icons/nav/ic_home.svg';

// comentado porque não se usa por enquanto
// import profile from '@/assets/icons/nav/ic_profile.svg';
// import notify from '@/assets/icons/nav/ic_notify.svg';
import support from '@/assets/icons/nav/ic_support.svg';
import wallet from '@/assets/icons/nav/ic_wallet.svg';
import market from '@/assets/icons/nav/ic_market.svg';

// Icons Enabled
import homeSelected from '@/assets/icons/nav/icons-enabled/home_enabled.svg';
import walletSelected from '@/assets/icons/nav/icons-enabled/wallet_enable.svg';
import supportSelected from '@/assets/icons/nav/icons-enabled/support_enable.svg';
import extractSelected from '@/assets/icons/nav/icons-enabled/extract_enable.svg';
import marketSelected from '@/assets/icons/nav/icons-enabled/market_enable.svg';

export default {
  name: 'NavigationDrawer',
  data() {
    return {
      itemLogout: {
        title: this.$t('components.nav.items.logout.title'),
        icon: logout,
        action: 'logout',
      },
      itemsNavCamrCooperative: [
        {
          title: this.$t('components.nav.items.home.title'),
          icon: home,
          selected: homeSelected,
          action: 'home',
          destination: ['home', 'send-nfe', 'create-credit-offered'],
        },
        {
          title: this.$t('components.nav.items.offer_credit.title'),
          icon: wallet,
          selected: walletSelected,
          action: 'offer-credit',
          destination: ['offer-credit'],
        },
        {
          title: this.$t('components.nav.items.my_offereds.title'),
          selected: extractSelected,
          icon: extract,
          action: 'my-offereds',
          destination: ['my-offereds'],
        },
        {
          title: this.$t('components.nav.items.wallet.title'),
          icon: wallet,
          selected: walletSelected,
          action: 'wallet',
          destination: ['wallet'],
        },
        {
          title: 'Cadastrar Conta',
          icon: wallet,
          selected: walletSelected,
          action: 'bank-account',
          destination: ['bank-account'],
        },

        // comentado porque não se usa por enquanto
        // {
        //   title: this.$t('components.nav.items.notify.title'),
        //   icon: notify,
        //   selected: null,
        //   action: 'notify',
        //   destination: ['notify'],
        // },
        // {
        //   title: this.$t('components.nav.items.extract.title'),
        //   icon: extract,
        //   selected: null,
        //   action: 'extract',
        //   destination: ['extract'],
        // },
        {
          title: this.$t('components.nav.items.documents.title'),
          selected: extractSelected,
          icon: extract,
          action: 'document',
          destination: ['document'],
        },
        {
          title: this.$t('components.nav.items.sac.title'),
          icon: support,
          selected: supportSelected,
          action: 'sac',
          destination: ['sac'],
        },

        // comentado porque não se usa por enquanto
        // {
        //   title: this.$t('components.nav.items.profile.title'),
        //   icon: profile,
        //   selected: null,
        //   action: 'profile',
        //   destination: ['profile'],
        // },
      ],
      itemsNavIndustryRepresentative: [
        {
          title: this.$t('components.nav.items.home.title'),
          icon: home,
          selected: homeSelected,
          action: 'home',
          destination: ['home'],
        },
        {
          title: this.$t('components.nav.items.credit_offered.title'),
          icon: wallet,
          selected: walletSelected,
          action: 'credit-offered',
          destination: ['credit-offered', 'checkout', 'cart', 'offered'],
        },
        {
          title: this.$t('components.nav.items.market.title'),
          icon: market,
          selected: marketSelected,
          action: 'market',
          destination: ['market'],
        },
        {
          title: this.$t('components.nav.items.sac.title'),
          icon: support,
          selected: supportSelected,
          action: 'sac',
          destination: ['sac'],
        },
        {
          title: this.$t('components.nav.items.orders.title'),
          icon: wallet,
          selected: walletSelected,
          action: 'orders',
          destination: 'orders',
        },
        {
          title: this.$t('components.nav.items.certificates.title'),
          selected: extractSelected,
          icon: extract,
          action: 'certificates',
          destination: ['certificates'],
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'getBusiness',
    ]),
    user() {
      if (this.currentUser) {
        return this.currentUser;
      }
      return {};
    },
    representative() {
      return this.getBusiness.kind === 'representative_business_industries';
    },
    logisticOperator() {
      return this.getBusiness.kind === 'cooperative_association' || this.getBusiness.kind === 'waste_merchant';
    },
    itemsNav() {
      if (this.logisticOperator) {
        return this.itemsNavCamrCooperative;
      }
      return this.itemsNavIndustryRepresentative;
    },
  },
  watch: {
    getBusiness(value) {
      if (!value.power_bi) {
        const index = this.itemsNavCamrCooperative.findIndex((item) => item.action === 'offer-credit');

        if (index >= 0) this.itemsNavCamrCooperative.splice(index, 1);
      }
    },
  },
  methods: {
    itemSelected(item) {
      if (item.includes(this.$route.name)) {
        return 'route-selected';
      }
      return '';
    },
    routeSelected(item) {
      if (item.includes(this.$route.name)) {
        return 'item-selected';
      }
      return '';
    },
    iconEnable(item) {
      return item.includes(this.$route.name);
    },
    logout() {
      this.$firebase.auth().signOut().then(() => {}).catch(() => {});
      this.$store.dispatch('logout');
    },
    goTo(path) {
      if (this.$route.name !== path) {
        this.$router.push({ name: path });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.navigation-drawer {
  font-family: Montserrat;
  height: 100vh;
  background: $nav;
  .content-nav {
    align-self: baseline;
    .item-selected {
      color: $color_primary;
      background-color: $item_selected;
      border-right: 2px solid $color_primary;
    }
  }
  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: $files_backgrond;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $color_primary;
    border-radius: 20px;
    border: 0.1875em solid $files_backgrond;
  }
  .nav-container{
    display: grid;
    grid-template-columns: auto;
    grid-auto-rows: 1fr 3fr 1fr;
    align-items: center;
    height: 100%;
    .item {
      height: 62px;
      .icon-img {
        width: 34px;
      }
    }
    .item-end{
      align-self: end;
    }
    .item-nav{
      font-size: 1em;
      font-weight: 600;
      color: $item_nav;
    }
    .route-selected {
      color: $color_primary;
    }
    .nav-logo {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      align-items: center;
      .img-logo {
        width: 7em;
      }
    }
  }
}
</style>
