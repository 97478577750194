const state = {
  confirmOrderDialog: false,
};

const getters = {
  getConfirmOrderDialog: () => (state.confirmOrderDialog),
};

const actions = {
  confirmOrderDialog({ commit }, payload) {
    commit('setDialog', payload);
  },
};

const mutations = {
  setDialog($state, payload) {
    const stateCopy = $state;

    stateCopy.confirmOrderDialog = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
