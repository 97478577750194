<template>
  <div class="vordesk-container">
    <div class="vordesk" />
  </div>
</template>
<script>
export default {
  name: 'Vordesk',
  mounted() {
    const BASE_URL = process.env.VUE_APP_VORDESK;
    const createElementChat = document.createElement('script');
    createElementChat.setAttribute('id', 'vordesk-chat');
    const getElementChat = document.getElementsByTagName('script')[0];

    createElementChat.src = `${BASE_URL}packs/js/sdk.js`;
    getElementChat.parentNode.insertBefore(createElementChat, getElementChat);

    createElementChat.onload = () => {
      window.chatwootSDK.run({
        websiteToken: process.env.VUE_APP_VORDESK_TOKEN,
        baseUrl: BASE_URL,
      });
    };
  },
  methods: {
    showBubble() {
      window.$chatwoot.toggle();
    },
  },
};
</script>
<style lang="scss" scoped>
.vordesk {
  width: 100%;
  height: 100%;
}
</style>
