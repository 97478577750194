import CartItem from '@/api/resources/cartItem';
import i18n from '@/plugins/i18n';

const state = {
  cartItems: [],
};

const getters = {
  getCartItems: () => (state.cartItems),
  getCartItem: () => (state.cartItem),
  getAmountCartItems: () => (state.cartItems.length),
};

const actions = {
  addToCart({ dispatch }, payload) {
    CartItem.create(payload).then(() => {
      dispatch('addSuccessMessage', i18n.t('success.added_to_cart'));
      dispatch('fetchCartItems');
    });
  },

  fetchCartItems({ commit, dispatch }, payload) {
    dispatch('initLoading');
    CartItem.index(payload).then((response) => {
      commit('setCartItems', response);
      dispatch('removeLoading');
    });
  },

  deleteCartItem({ dispatch }, payload) {
    CartItem.delete(payload.id).then(() => {
      dispatch('fetchCartItems');
      dispatch('removeLoading');
    });
  },

  updateCartItem({ dispatch }, payload) {
    CartItem.update(payload).then(() => {
      dispatch('fetchCartItems');
      dispatch('removeLoading');
    });
  },
};

const mutations = {
  setCartItems($state, payload) {
    const stateCopy = $state;
    stateCopy.cartItems = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
