import Nfe from '@/api/resources/nfe';
import i18n from '@/plugins/i18n';

const state = {
  nfes: [],
  nfesToOffer: [],
  myNfes: [],
};

const getters = {
  getNfesFiles: () => (state.nfes),
  getMyNfesFiles: () => (state.myNfes),
  getNfesToOffer: () => (state.nfesToOffer),
};

const actions = {
  sendFilesNfes({ dispatch }, payload) {
    dispatch('initLoading');
    Nfe.send(payload).then(() => {
      dispatch('removeLoading');
      dispatch('nfeSendSuccess', true);
    });
  },

  getMyNfes({ commit, dispatch }, payload) {
    dispatch('initLoading');
    Nfe.index(payload).then((response) => {
      commit('setMyNfes', response);
      dispatch('removeLoading');
    });
  },

  fetchNfesToOffer({ commit, dispatch }, payload) {
    dispatch('initLoading');
    Nfe.fetchNfeToOffer(payload).then((response) => {
      commit('setNfesToOffer', response);
      dispatch('removeLoading');

      if (response.length === 0) dispatch('addErrorMessage', i18n.t('errors.nfes_to_offer_not_found'));
    });
  },

  resetNfesToOffer({ commit }) {
    commit('cleanNfesToOffer');
  },

  setNfeFileToSend({ commit, dispatch }, payload) {
    commit('setNfeFileToSend', payload);
    dispatch('nextStepNfe');
  },

  resetNfesFiles({ commit }) {
    commit('setNfeFileToSend', []);
  },
};

const mutations = {
  setMyNfes($state, payload) {
    const stateCopy = $state;
    stateCopy.myNfes = payload;
  },

  setNfeFileToSend($state, payload) {
    const stateCopy = $state;
    stateCopy.nfes = payload;
  },

  setNfesToOffer($state, payload) {
    const stateCopy = $state;
    stateCopy.nfesToOffer.push(...payload);
  },

  cleanNfesToOffer($state) {
    const stateCopy = $state;
    stateCopy.nfesToOffer = [];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
