import './plugins';
import Vue from 'vue';
import VueTheMask from 'vue-the-mask';
import ActionCableVue from 'actioncable-vue';
import App from './App.vue';
import i18n from './plugins/i18n';
import vuetify from './plugins/vuetify';
import store from './store';
import router from './routes';

Vue.use(VueTheMask);
Vue.config.productionTip = false;

Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: 'error',
  connectionUrl: process.env.VUE_APP_CABLE,
  connectImmediately: true,
});

new Vue({
  vuetify,
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount('#app');
