import { render, staticRenderFns } from "./CustomLoading.vue?vue&type=template&id=ef79d044&scoped=true"
import script from "./CustomLoading.vue?vue&type=script&lang=js"
export * from "./CustomLoading.vue?vue&type=script&lang=js"
import style0 from "@/assets/scss/components/feedbacks/custom_load.scss?vue&type=style&index=0&id=ef79d044&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef79d044",
  null
  
)

export default component.exports