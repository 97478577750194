import PriceTable from '@/api/resources/priceTable';

const state = {
  priceTable: {},
};

const getters = {
  getPriceTable: (state) => state.priceTable,
};

const actions = {
  fetchPriceTable({ commit, dispatch }, payload) {
    dispatch('initLoading');

    PriceTable.indexPriceTable(payload).then((response) => {
      dispatch('removeLoading');
      commit('setPriceTable', response);
    });
  },
};

const mutations = {
  setPriceTable($state, payload) {
    const stateCopy = $state;
    stateCopy.priceTable = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
