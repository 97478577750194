const state = {
  relatedOffersDialog: false,
};

const getters = {
  getRelatedOffersDialog: () => (state.relatedOffersDialog),
};

const actions = {
  openRelatedOffersDialog({ commit }) {
    commit('setRelatedOffersDialog', true);
  },

  closeRelatedOffersDialog({ commit }) {
    commit('setRelatedOffersDialog', false);
  },

  setRelatedOffers({ commit }, payload) {
    commit('setRelatedOffers', payload);
  },
};

const mutations = {
  setRelatedOffersDialog($state, payload) {
    const stateCopy = $state;
    stateCopy.relatedOffersDialog = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
